import ReactLoadable from '@yeutech-lab/react-loadable';
import DefaultLoadingComponent from './LoadingComponent';

/**
 * @public
 * @name LoadingComponent
 * @description
 * This loading component can be used with react-loadable to retrieve loading from the context
 * @example
 * import Loadable from '@yeutech-lab/react-loadable';
 * import { LoadingComponent } from '@yeutech/react-loadable';
 * export default Loadable({
 *   loading: LoadingComponent,
 *   loader: () => import('./index')
 * });
 */
export const LoadingComponent = DefaultLoadingComponent;

/**
 * @public
 * @name loadableSettings
 * @description
 * It is the default react-loadable configuration used within this package.
 * It provide a default timeout of 10 seconds and a loading component that retrieve real loading component from application context
 * @type {{loading: *, timeout: number}}
 * @example
 * import Loadable from '@yeutech-lab/react-loadable';
 * import { loadableSettings } from '@yeutech/react-loadable';
 * export default Loadable({
 *   ...loadableSettings,
 *   loading: () => <div>loading...</div>,
 *   loader: () => import('./index'),
 * });
 */
export const loadableSettings = {
  loading: LoadingComponent,
  timeout: 10000, // 10 seconds
};

/**
 * @public
 * @name Loadable
 * @description
 * A react-loadable wrapper that use @yeutech/app-context to retrieve the loading component
 * @param {object} [opts={}] - react-loadable options (see all possible options: https://github.com/jamiebuilds/react-loadable)
 * @param {number} [opts.timeout=10000] - Activates props timedOut to display the timeout message after 10 seconds.
 * @param {function} [opts.loading=LoadingComponent] - A loading component that use app context to retrieve the real loading component
 * @returns {Loadable} react-loadable component
 * @example
 * const Loadable = require('@yeutech/react-loadable');
 * export default Loadable({
 *   loader: () => import('./index'),
 * });
 */
export default function Loadable(opts) {
  return ReactLoadable({ ...loadableSettings, ...opts });
}
