import React, { useContext } from 'react';
import { AppContext } from '@yeutech/app-context';

/**
 * @name LoadingComponent
 * @description
 * This component read the AppContext and retrieve a loading component which will be used for rendering
 * @param props - react prop types
 * @return {null|*} - The rendered loading component
 */
export default function LoadingComponent(props) {
  const { loadingComponent: ContextLoadingComponent } = useContext(AppContext);
  return ContextLoadingComponent ? <ContextLoadingComponent {...props} /> : null;
}
